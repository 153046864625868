<template>
  <v-container>
    <h3>Аналитика</h3>
    <div style="width: 400px;">
      <LineChart   :options="{responsive: true}" v-if="loading" :chart-data="graph"/>
    </div>
  </v-container>
</template>

<script>
import LineChart from './Charts/LineChart.js'
import stats from "@/api/stats";

export default {
  name: "ProjectsAnalytics",
  components: {
    LineChart
  },
  data() {
    return {
      loading: false,
      graph: {
        labels: [],
        datasets: []
      }
    }
  },
  mounted() {
    stats.getProjectsStats().then((r) => {
      this.loading = true;
      this.graph.labels = Object.keys(r.data);
      this.graph.datasets = [{label: 'Кол-во проектов', backgroundColor: 'rgba(0,112,243,0.7)', data: Object.values(r.data).map((d) => d.count)}]
    });
  }
}
</script>

<style scoped>

</style>
